import React, { useState, useEffect } from 'react';
import { Auth } from 'aws-amplify';
import store from 'store';
import { navigate } from 'gatsby';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import TransitionAlert from '../components/transition-alert';
import useAuth from '../components/use-auth';
import useFetchTenants from '../components/use-fetch-tenants';
import Layout from '../components/layout';
import useFormInput from '../components/use-form-input';
import Loading from '../components/loading';
import useLoginStyles from '../components/use-login-styles';

const getDefaultTenant = (auth) => {
  if (!auth) return;
  try {
    const { tenantId } = store.get('settings');
    return tenantId;
  } catch (e) {
    return;
  }
}

function SelectTenantPage({path}) {
  const auth = useAuth();
  const classes = useLoginStyles();
  
  const tenantId = useFormInput();
  const { tenants, isLoading: isLoadingTenants, isError: isErrorTenants } = useFetchTenants();
  const handleOnSubmit = async (e) => {
    e.preventDefault();
    const tenantAccount = (tenants.find(t => t.id === tenantId.value) || {}).account;
    store.set('settings', {  ...store.get('settings'), tenantId: tenantId.value, tenantAccount })
    navigate('/select-store');
  }
  const handleLogout = async () =>{
    await Auth.signOut();
    navigate('/login');
  }
  useEffect(() => {
    const defaultTenant = getDefaultTenant(auth);
    tenantId.setValue(defaultTenant || '');
  }, [auth]);
  
  return (
    <Layout path={path}>
      <Container component="main" maxWidth="xs">
        <div className={classes.paper}>
          <Typography component="h1" variant="h5">
            Select Tenant
          </Typography>
          { isLoadingTenants && <Loading /> }
          { isErrorTenants && (
            <TransitionAlert className={classes.alert} severity="error" isOpen>Error fetching tenants</TransitionAlert>
          ) }
          { !isLoadingTenants && !isErrorTenants && (
            <form className={classes.form} noValidate>
              <FormControl variant="outlined" fullWidth className={classes.formControl}>
                <InputLabel id="tenant-label">Tenant</InputLabel>
                <Select
                  labelId="tenant-label"
                  id="tenant"
                  value={tenantId.value}
                  onChange={tenantId.onChange}
                  label="Tenant"
                >
                  { tenants.map( s => <MenuItem key={s.id} value={s.id}>{s.description} ({s.account})</MenuItem>) }
                </Select>
              </FormControl>
              <div></div>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                onClick={handleOnSubmit}
              >
                Select tenant
              </Button>
              <Button
                type="button"
                fullWidth
                variant="outlined"
                color="primary"
                className={classes.submit}
                onClick={handleLogout}
              >
                Log out
              </Button>
            </form>
          )}
        </div>
      </Container>
    </Layout>
  );
}

export default SelectTenantPage;
